<template>
  <overlay-scrollbars class="page page-request">
    <div class="box box_payments">
      <div class="header">
        <div class="title">Payments</div>
      </div>
      <div class="content">
        <div class="block block__bankAcc">
          <div class="ttl">
            <Bank2 class="img" />
            <div class="text">Bank account (wire)</div>
          </div>
          <div class="main">
            <div class="box box_left box_tmblers box_arrow-r">
              <div class="box_ttl">Payment methods you need</div>
              <div class="box_list" :key="paymentMethodsListKey">
                <div v-for="(item, key) in paymentMethods" :key="key" class="list_item">
                  <Tmblr :active="item.active" :text="item.name" @changeState="paymentMethodsChangeState(item)" />
                </div>
              </div>
              <div class="arrow-r"><Arrow /></div>
            </div>
            <div class="main_center">
              <div class="box box_prcs">
                <div class="block">
                  <div class="ttl">Minimum ammount ($)</div>
                  <div class="val">
                    <input type="text" class="form-control" v-model="request.ba_minimum_ammount" />
                    <span v-show="errors.ba_minimum_ammount" class="invalid-feedback">{{ errors.ba_minimum_ammount }}</span>
                  </div>
                </div>
                <div class="block">
                  <div class="ttl">Maximum ammount ($)</div>
                  <div class="val">
                    <input type="text" class="form-control" v-model="request.ba_maximum_ammount" />
                    <span v-show="errors.ba_maximum_ammount" class="invalid-feedback">{{ errors.ba_maximum_ammount }}</span>
                </div>
                </div>
                <div class="block">
                  <div class="ttl">Purpose of payment</div>
                  <div class="val">
                    <input type="text" class="form-control" v-model="request.ba_payment_purpose" />
                    <span v-show="errors.ba_payment_purpose" class="invalid-feedback">{{ errors.ba_payment_purpose }}</span>
                  </div>
                </div>
              </div>
              <div class="box box_prcs">
                <div class="block">
                  <div class="ttl">Incoming fee</div>
                  <div class="val">
                    <input type="text" class="form-control" v-model="request.ba_incoming_fee" />
                    <span v-show="errors.ba_incoming_fee" class="invalid-feedback">{{ errors.ba_incoming_fee }}</span>
                  </div>
                </div>
                <div class="block">
                  <div class="ttl">Outgoing fee</div>
                  <div class="val">
                    <input type="text" class="form-control" v-model="request.ba_outgoing_fee" />
                    <span v-show="errors.ba_outgoing_fee" class="invalid-feedback">{{ errors.ba_outgoing_fee }}</span>
                  </div>
                </div>
                <div class="block">
                  <div class="ttl">Recall reason</div>
                  <div class="val">
                    <input type="text" class="form-control" v-model="request.ba_recall_reason" />
                  </div>
                </div>
              </div>
            </div>
            <div class="box box_right box_tmblers box_arrow-l">
              <div class="box_ttl">List of Account currencies</div>
              <div class="box_list box_list-2" :key="accountCurrenciesIndex">
                <div v-for="(item, key) in accountCurrencies" :key="key" class="list_item">
                  <Tmblr :active="item.active" :text="item.code" @changeState="accountCurrenciesChangeState(item)" />
                </div>
              </div>
              <div v-if="showOtherAccountCurrency">
                <div class="box_ttl">Type of Account</div>
                <input v-model="request.other_account_currency" type="text" class="inpt inpt_acc" placeholder="Enter type">
              </div>
              <div class="arrow-r"><Arrow /></div>
            </div>
          </div>
        </div>
        <div class="block block__merchantAcc">
          <div class="ttl">
            <CreditCards class="img" />
            <div class="text">Merchant account</div>
          </div>
          <div class="main">
            <div class="box box_left box_tmblers box_arrow-r">
              <div class="box_ttl">Type of cards you need</div>
              <div class="box_list box_list-2" :key="cardTypesIndex">
                <div v-for="(item, key) in cardTypes" :key="key" class="list_item">
                  <Tmblr :active="item.active" :text="item.name" @changeState="cardTypesChangeState(item)" />
                </div>
              </div>
              <div class="arrow-r"><Arrow /></div>
            </div>
            <div class="main_center">
              <div class="box box_prcs">
                <div class="block">
                  <div class="ttl">Minimum ammount ($)</div>
                  <div class="val">
                    <input type="text" class="form-control" v-model="request.ma_minimum_ammount" />
                    <span v-show="errors.ma_minimum_ammount" class="invalid-feedback">{{ errors.ma_minimum_ammount }}</span>
                  </div>
                </div>
                <div class="block">
                  <div class="ttl">Maximum ammount ($)</div>
                  <div class="val">
                    <input type="text" class="form-control" v-model="request.ma_maximum_ammount" />
                    <span v-show="errors.ma_maximum_ammount" class="invalid-feedback">{{ errors.ma_maximum_ammount }}</span>
                  </div>
                </div>
                <div class="block">
                  <div class="ttl">Type of your MC code needed</div>
                  <div class="val">
                    <input type="text" class="form-control" v-model="request.ma_mc_code_type" />
                    <span v-show="errors.ma_maximum_ammount" class="invalid-feedback">{{ errors.ma_maximum_ammount }}</span>
                  </div>
                </div>
              </div>
              <div class="box box_prcs">
                <div class="block">
                  <div class="ttl">Incoming fee</div>
                  <div class="val">
                    <input type="text" class="form-control" v-model="request.ma_incoming_fee" />
                    <span v-show="errors.ma_incoming_fee" class="invalid-feedback">{{ errors.ma_incoming_fee }}</span>
                  </div>
                </div>
                <div class="block">
                  <div class="ttl">Outgoing fee</div>
                  <div class="val">
                    <input type="text" class="form-control" v-model="request.ma_outgoing_fee" />
                    <span v-show="errors.ma_outgoing_fee" class="invalid-feedback">{{ errors.ma_outgoing_fee }}</span>
                  </div>
                </div>
                <div class="block">
                  <div class="ttl">Chargeback reason</div>
                  <div class="val">
                    <input type="text" class="form-control" v-model="request.ma_chargeback_reason" />
                    <span v-show="errors.ma_chargeback_reason" class="invalid-feedback">{{ errors.ma_chargeback_reason }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="box box_right box_tmblers box_arrow-l">
              <div class="box_ttl">List of card processing currencies</div>
              <div class="box_list box_list-2" :key="processingCurrenciesIndex">
                <div v-for="(item, key) in processingCurrencies" :key="key" class="list_item">
                  <Tmblr :active="item.active" :text="item.code" @changeState="processingCurrenciesChangeState(item)" />
                </div>
              </div>
              <input v-model="request.other_processing_currency" v-if="showOtherProcessingCurrency" type="text" class="inpt inpt_procCurr" placeholder="Enter type">
              <div class="arrow-r"><Arrow /></div>
            </div>
          </div>
        </div>
        <div class="block block__merchantAcc">
          <div class="ttl">
            <CreditCards class="img" />
            <div class="text">Card Issuing ( min. 1k cards)</div>
          </div>
          <div class="main">
            <div class="box box_left box_tmblers box_arrow-r">
              <div class="box_ttl">Type of cards you need</div>
              <div class="box_list box_list-2" :key="cardIssuingTypesIndex">
                <div v-for="(item, key) in cardIssuingCardTypes" :key="key" class="list_item">
                  <Tmblr :active="item.active" :text="item.name" @changeState="cardIssuingCardTypesChangeState(item)" />
                </div>
              </div>
              <div class="arrow-r"><Arrow /></div>
            </div>
            <div class="main_center">
              <div class="box box_prcs">
                <div class="block">
                  <div class="ttl">Amount of Card</div>
                  <div class="val">
                    <input type="text" class="form-control" v-model="request.card_ammount" />
                    <span v-show="errors.card_ammount" class="invalid-feedback">{{ errors.card_ammount }}</span>
                  </div>
                </div>
                <div class="block">
                  <div class="ttl">Branded Card</div>
                  <div class="val">
                    <select class="form-control" v-model="request.card_branded">
                      <option value="1">Yes</option>
                      <option value="0">No</option>
                    </select> 
                    <span v-show="errors.card_branded" class="invalid-feedback">{{ errors.card_branded }}</span>
                  </div>
                </div>
                <div class="block">
                  <div class="ttl">Region of card Use</div>
                  <div class="val">
                    <input type="text" class="form-control" v-model="request.card_region" />
                    <span v-show="errors.card_region" class="invalid-feedback">{{ errors.card_region }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="box box_right box_tmblers box_arrow-l">
              <div class="box_ttl">List of card processing currencies</div>
              <div class="box_list box_list-2" :key="cardIssuingProcessingCurrenciesIndex">
                <div v-for="(item, key) in cardIssuingProcessingCurrencies" :key="key" class="list_item">
                  <Tmblr :active="item.active" :text="item.code" @changeState="cardIssuingProcessingCurrenciesChangeState(item)" />
                </div>
              </div>
              <input v-model="request.card_other_processing_currency" v-if="cardIssuingShowOtherProcessingCurrency" type="text" class="inpt inpt_procCurr" placeholder="Enter type">
              <div class="arrow-r"><Arrow /></div>
            </div>
          </div>
        </div>
        <div class="footer">
          <div @click="submit" class="btn btn_submit">Submit data</div>
        </div>
      </div>
    </div>
  </overlay-scrollbars>
</template>

<script>
import Bank2 from '@/components/img/Bank2.vue'
import Tmblr from '@/components/elem/Tmblr.vue'
import Arrow from '@/components/img/Arrow.vue'
import CreditCards from '@/components/img/CreditCards.vue'

export default {
  components: {
    Bank2,
    Tmblr,
    Arrow,
    CreditCards,
  },
  data: () => ({
    userPaymentMethods: [],
    userAccountCurrencies: [],
    userCardTypes: [],
    userCardIssuingCardTypes: [],
    userProcessingCurrencies: [],
    userCardIssuingProcessingCurrencies: [],
    paymentMethods: [],
    paymentMethodsListKey: 0,
    accountCurrencies: [],
    accountCurrenciesIndex:0,
    cardTypes: [],
    cardTypesIndex:0,
    cardIssuingCardTypes: [],
    cardIssuingTypesIndex:0,
    processingCurrencies: [],
    processingCurrenciesIndex:0,
    cardIssuingProcessingCurrencies: [],
    cardIssuingProcessingCurrenciesIndex:0,
    showOtherAccountCurrency: false,
    showOtherProcessingCurrency: false,
    cardIssuingShowOtherProcessingCurrency: false,
    request: {
      ba_incoming_currencies: [],
      ba_incoming_fee: '',
      ba_maximum_ammount: '',
      ba_minimum_ammount: '',
      ba_outgoing_fee: '',
      ba_payment_methods: [],
      ba_payment_purpose: '',
      ba_recall_reason: '',
      ma_card_types: [],
      ma_chargeback_reason: '',
      ma_incoming_fee: '',
      ma_maximum_ammount: '',
      ma_mc_code_type: '',
      ma_minimum_ammount: '',
      ma_outgoing_fee: '',
      ma_processing_currencies: [],
      user_id: null,
      card_ammount: '',
      card_branded: 0,
      card_region: '',
    },
    errors: {
      ba_incoming_fee: '',
      ba_maximum_ammount: '',
      ba_minimum_ammount: '',
      ba_outgoing_fee: '',
      ba_payment_purpose: '',
      ba_recall_reason: '',
      ma_chargeback_reason: '',
      ma_incoming_fee: '',
      ma_maximum_ammount: '',
      ma_mc_code_type: '',
      ma_minimum_ammount: '',
      ma_outgoing_fee: '',
      card_ammount: '',
    }
  }),
  created () {
    this.getAccountCurrencies()
    this.getProcessingCurrencies()
    this.getCardIssuingProcessingCurrencies()
    this.getPaymentMethods()
    this.getCardTypes()
    this.getCardIssuingCardTypes()
    this.getRequest()
  },
  methods: {
    clearErrors () {
      for (let key in this.errors) {
        this.errors[key] = ''
      }
    },
    submit () {
      this.clearErrors()
      const that = this
      let userPaymentMethodIds = this.paymentMethods.filter(function(el) { return el.active })
      userPaymentMethodIds = userPaymentMethodIds.map(el => el.id)
      let accountCurrenciesIds = this.accountCurrencies.filter(function(el) { return el.active })
      accountCurrenciesIds = accountCurrenciesIds.map(el => el.id)
      let cardTypesIds = this.cardTypes.filter(function(el) { return el.active })
      cardTypesIds = cardTypesIds.map(el => el.id)

      let cardIssuingCardTypesIds = this.cardIssuingCardTypes.filter(function(el) { return el.active })
      cardIssuingCardTypesIds = cardIssuingCardTypesIds.map(el => el.id)

      let processingCurrenciesIds = this.processingCurrencies.filter(function(el) { return el.active })
      processingCurrenciesIds = processingCurrenciesIds.map(el => el.id)

      let cardIssuingProcessingCurrenciesIds = this.cardIssuingProcessingCurrencies.filter(function(el) { return el.active })
      cardIssuingProcessingCurrenciesIds = cardIssuingProcessingCurrenciesIds.map(el => el.id)

      axios.post(process.env.VUE_APP_BACKEND_API_URL + 'request/update', {
        bank_account_payment_method_ids: userPaymentMethodIds,
        bank_account_incoming_currency_ids: accountCurrenciesIds,
        merchant_account_card_type_ids: cardTypesIds,
        merchant_account_processing_currency_ids: processingCurrenciesIds,
        other_account_currency: this.request.other_account_currency,
        other_processing_currency: this.request.other_processing_currency,
        ba_incoming_fee: this.request.ba_incoming_fee,
        ba_maximum_ammount: this.request.ba_maximum_ammount,
        ba_minimum_ammount: this.request.ba_minimum_ammount,
        ba_outgoing_fee: this.request.ba_outgoing_fee,
        ba_payment_purpose: this.request.ba_payment_purpose,
        ba_recall_reason: this.request.ba_recall_reason,
        ma_chargeback_reason: this.request.ma_chargeback_reason,
        ma_incoming_fee: this.request.ma_incoming_fee,
        ma_maximum_ammount: this.request.ma_maximum_ammount,
        ma_mc_code_type: this.request.ma_mc_code_type,
        ma_minimum_ammount: this.request.ma_minimum_ammount,
        ma_outgoing_fee: this.request.ma_outgoing_fee,
        card_ammount: this.request.card_ammount,
        card_branded: this.request.card_branded,
        card_region: this.request.card_region,
        card_issuing_card_type_ids: cardIssuingCardTypesIds,
        card_issuing_processing_currency_ids: cardIssuingProcessingCurrenciesIds,
        card_other_processing_currency: this.request.card_other_processing_currency,
      })
        .then(function (response) {
          if (response.data.error) {
            for (let serverError in response.data.errors) {
              for (let cilentError in that.errors) {
                if (serverError === cilentError && response.data.errors[serverError][0]) {
                  that.errors[cilentError] = response.data.errors[serverError][0]  
                }
              }
            }
            return
          }
          that.$noty.success("Your request data was successfully changed")
          console.log(response)
        })
        .catch(function (error) {
          console.log(error);
        })
    },
    paymentMethodsChangeState (item) {
      const index = this.paymentMethods.findIndex(paymentMethod => {
          return (item.id === paymentMethod.id)
      })
      this.$set(this.paymentMethods[index], 'active', !item.active)
      this.paymentMethodsListKey++
    },
    accountCurrenciesChangeState (item) {
      const index = this.accountCurrencies.findIndex(accountCurrency => {
          return (item.id === accountCurrency.id)
      })
      this.$set(this.accountCurrencies[index], 'active', !item.active)
      if (this.accountCurrencies[index].code == 'Other') {
        if (this.accountCurrencies[index].active) {
          this.showOtherAccountCurrency = true
        } else {
          this.showOtherAccountCurrency = false
          this.request.other_account_currency = ''
        }
      }
      this.accountCurrenciesIndex++
    },
    cardTypesChangeState (item) {
      const index = this.cardTypes.findIndex(cardType => {
          return (item.id === cardType.id)
      })
      this.$set(this.cardTypes[index], 'active', !item.active)
      this.cardTypesIndex++
    },
    cardIssuingCardTypesChangeState (item) {
      const index = this.cardIssuingCardTypes.findIndex(cardType => {
          return (item.id === cardType.id)
      })
      this.$set(this.cardIssuingCardTypes[index], 'active', !item.active)
      this.cardIssuingTypesIndex++
    },
    processingCurrenciesChangeState (item) {
      const index = this.processingCurrencies.findIndex(processingCurrency => {
          return (item.id === processingCurrency.id)
      })
      this.$set(this.processingCurrencies[index], 'active', !item.active)
      if (this.processingCurrencies[index].code == 'Other') {
        if (this.processingCurrencies[index].active) {
          this.showOtherProcessingCurrency = true
        } else {
          this.showOtherProcessingCurrency = false
          this.request.other_processing_currency = ''
        }
      }
      this.processingCurrenciesIndex++
    },
    cardIssuingProcessingCurrenciesChangeState (item) {
      const index = this.cardIssuingProcessingCurrencies.findIndex(processingCurrency => {
          return (item.id === processingCurrency.id)
      })
      this.$set(this.cardIssuingProcessingCurrencies[index], 'active', !item.active)
      if (this.cardIssuingProcessingCurrencies[index].code == 'Other') {
        if (this.cardIssuingProcessingCurrencies[index].active) {
          this.cardIssuingShowOtherProcessingCurrency = true
        } else {
          this.cardIssuingShowOtherProcessingCurrency = false
          this.request.card_other_processing_currency = ''
        }
      }
      this.cardIssuingProcessingCurrenciesIndex++
    },  
    getAccountCurrencies () {
      const that = this
      axios.get(process.env.VUE_APP_BACKEND_API_URL + 'data/currencies')
        .then(function (response) {
          that.accountCurrencies = response.data
        })
        .catch(function (error) {
          console.log(error);
        })
    },
    getProcessingCurrencies () {
      const that = this
      axios.get(process.env.VUE_APP_BACKEND_API_URL + 'data/currencies')
        .then(function (response) {
          that.processingCurrencies = response.data
          that.cardIssuingProcessingCurrencies = response.data
        })
        .catch(function (error) {
          console.log(error);
        })
    },
    getCardIssuingProcessingCurrencies () {
      const that = this
      axios.get(process.env.VUE_APP_BACKEND_API_URL + 'data/currencies')
        .then(function (response) {
          that.cardIssuingProcessingCurrencies = response.data
        })
        .catch(function (error) {
          console.log(error);
        })
    },
    getPaymentMethods () {
      const that = this
      axios.get(process.env.VUE_APP_BACKEND_API_URL + 'data/payment-methods')
        .then(function (response) {
          that.paymentMethods = response.data
        })
        .catch(function (error) {
          console.log(error);
        })
    },
    getCardTypes () {
      const that = this
      axios.get(process.env.VUE_APP_BACKEND_API_URL + 'data/card-types')
        .then(function (response) {
          that.cardTypes = response.data
        })
        .catch(function (error) {
          console.log(error);
        })
    },
    getCardIssuingCardTypes () {
      const that = this
      axios.get(process.env.VUE_APP_BACKEND_API_URL + 'data/card-types')
        .then(function (response) {
          that.cardIssuingCardTypes = response.data
        })
        .catch(function (error) {
          console.log(error);
        })
    },
    getRequest () {
      const that = this
      axios.get(process.env.VUE_APP_BACKEND_API_URL + 'request/show')
        .then(function (response) {
          /* if (response.data.data.ba_incoming_fee) {
            response.data.data.ba_incoming_fee = that.number_format(response.data.data.ba_incoming_fee, 0, '.', ' ')
          }
          if (response.data.data.ba_outgoing_fee) {
            response.data.data.ba_outgoing_fee = that.number_format(response.data.data.ba_outgoing_fee, 0, '.', ' ')
          }
          if (response.data.data.ba_maximum_ammount) {
            response.data.data.ba_maximum_ammount = that.number_format(response.data.data.ba_maximum_ammount, 0, '.', ' ')
          }
          if (response.data.data.ba_minimum_ammount) {
            response.data.data.ba_minimum_ammount = that.number_format(response.data.data.ba_minimum_ammount, 0, '.', ' ')
          }
          if (response.data.data.ma_incoming_fee) {
            response.data.data.ma_incoming_fee = that.number_format(response.data.data.ma_incoming_fee, 0, '.', ' ')
          }
          if (response.data.data.ma_outgoing_fee) {
            response.data.data.ma_outgoing_fee = that.number_format(response.data.data.ma_outgoing_fee, 0, '.', ' ')
          }
          if (response.data.data.ma_maximum_ammount) {
            response.data.data.ma_maximum_ammount = that.number_format(response.data.data.ma_maximum_ammount, 0, '.', ' ')
          }
          if (response.data.data.ma_minimum_ammount) {
            response.data.data.ma_minimum_ammount = that.number_format(response.data.data.ma_minimum_ammount, 0, '.', ' ')
          } */
          that.request = response.data.data
          that.request.card_branded = that.request.card_branded ? that.request.card_branded : 0
          if (that.request && that.request.user) {
            if (that.request.request_changed_date) {
              that.userPaymentMethods = that.request.user.bank_account_payment_methods
              that.userAccountCurrencies = that.request.user.bank_account_incoming_currencies
              that.userCardTypes = that.request.user.merchant_account_card_types
              that.userProcessingCurrencies = that.request.user.merchant_account_processing_currencies
              that.userCardIssuingCardTypes = that.request.user.card_issuing_card_types
              that.userCardIssuingProcessingCurrencies = that.request.user.card_issuing_processing_currencies
            } 
          }
        })
        .catch(function (error) {
          console.log(error);
        })
    }
  },
  computed: {
    isAllPaymentDataFetched() {
      if (this.paymentMethods.length > 0 && this.userPaymentMethods.length >0) {
        return true
      }
      return false
    },
    isAllAccountCurrenciesFetched() {
      if (this.accountCurrencies.length > 0 && this.userAccountCurrencies.length >0) {
        return true
      }
      return false
    },
    isAllCardTypesFetched() {
      if (this.cardTypes.length > 0 && this.userCardTypes.length >0) {
        return true
      }
      return false
    },
    isAllProcessingCurrenciesFetched() {
      if (this.cardTypes.length > 0 && this.userCardTypes.length >0) {
        return true
      }
      return false
    },
    isAllCardIssuingCardTypesFetched() {
      if (this.cardIssuingCardTypes.length > 0 && this.userCardIssuingCardTypes.length >0) {
        return true
      }
      return false
    },
    isAllCardIssuingProcessingCurrenciesFetched() {
      if (this.cardIssuingProcessingCurrencies.length > 0 && this.userCardIssuingProcessingCurrencies.length >0) {
        return true
      }
      return false
    }
  },
  watch: {
    isAllPaymentDataFetched: {
      handler: function(val) {
        for (let i = 0; i < this.paymentMethods.length; i++) {
          this.paymentMethods[i].active = false
          for (let z = 0; z < this.userPaymentMethods.length; z++) {
            if (this.paymentMethods[i].id === this.userPaymentMethods[z].id) {
              this.paymentMethods[i].active = true
            }
          }
        }
      },
      deep: true
    },
    isAllAccountCurrenciesFetched: {
      handler: function(val) {
        for (let i = 0; i < this.accountCurrencies.length; i++) {
          this.accountCurrencies[i].active = false
          for (let z = 0; z < this.userAccountCurrencies.length; z++) {
            if (this.accountCurrencies[i].id === this.userAccountCurrencies[z].id) {
              this.accountCurrencies[i].active = true
              if (this.accountCurrencies[i].code == 'Other') {
                this.showOtherAccountCurrency = true
              }
            }
          }
        }
      },
      deep: true
    },
    isAllCardTypesFetched: {
      handler: function(val) {
        for (let i = 0; i < this.cardTypes.length; i++) {
          this.cardTypes[i].active = false
          for (let z = 0; z < this.userCardTypes.length; z++) {
            if (this.cardTypes[i].id === this.userCardTypes[z].id) {
              this.cardTypes[i].active = true
            }
          }
        }
      },
      deep: true
    },
    isAllProcessingCurrenciesFetched: {
      handler: function(val) {
        for (let i = 0; i < this.processingCurrencies.length; i++) {
          this.processingCurrencies[i].active = false
          for (let z = 0; z < this.userProcessingCurrencies.length; z++) {
            if (this.processingCurrencies[i].id === this.userProcessingCurrencies[z].id) {
              this.processingCurrencies[i].active = true
              if (this.processingCurrencies[i].code == 'Other') {
                this.showOtherProcessingCurrency = true
              }
            }
          }
        }
      },
      deep: true
    },
    isAllCardIssuingCardTypesFetched: {
      handler: function(val) {
        for (let i = 0; i < this.cardIssuingCardTypes.length; i++) {
          this.cardIssuingCardTypes[i].active = false
          for (let z = 0; z < this.userCardIssuingCardTypes.length; z++) {
            if (this.cardIssuingCardTypes[i].id === this.userCardIssuingCardTypes[z].id) {
              this.cardIssuingCardTypes[i].active = true
            }
          }
        }
      },
      deep: true
    },
    isAllCardIssuingProcessingCurrenciesFetched: {
      handler: function(val) {
        for (let i = 0; i < this.cardIssuingProcessingCurrencies.length; i++) {
          this.cardIssuingProcessingCurrencies[i].active = false
          for (let z = 0; z < this.userCardIssuingProcessingCurrencies.length; z++) {
            if (this.cardIssuingProcessingCurrencies[i].id === this.userProcessingCurrencies[z].id) {
              this.cardIssuingProcessingCurrencies[i].active = true
              if (this.cardIssuingProcessingCurrencies[i].code == 'Other') {
                this.cardIssuingShowOtherProcessingCurrency = true
              }
            }
          }
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.page-request {
  .box {
    background: #000000;

    &_payments {
      .content {
        padding-top: 0;

        .block {
          padding-bottom: 1rem;

          .ttl {
            text-align: center;

            svg.img {
              width: 2.5rem;
              height: 2.5rem;
            }
          }

          .main {
            display: flex;
            align-items: center;
            justify-content: center;

            &_center {
              width: 45%;
            }

            .box {
              &_tmblers {
                padding: 1.5rem 2.5rem;
                background-color: #22252B;

                .box_list-2 {
                  display: flex;
                  flex-wrap: wrap;
                  padding-bottom: .75rem;

                  .list_item {
                    min-width: 45%;

                    @media (max-width: 1600px) {
                      width: 100%;
                    }
                  }
                }

                .row {
                  display: flex;

                  .box_list {
                    margin-right: 2rem;
                    margin-bottom: 1rem;
                  }
                }

                .box_ttl {
                  font-size: 14px;
                  padding-bottom: .5rem;
                }

                .inpt {
                  width: 100%;
                }
              }

              &_left {
                width: 25%;

                @media (max-width: 1440px) {
                  padding-left: 1.5rem;
                }
              }

              &_right {
                width: 25%;

                @media (max-width: 1440px) {
                  padding-right: 1.5rem;
                }
              }

              &_arrow-r {
                position: relative;
                margin-right: 3rem;

                .arrow-r {
                  top: 50%;
                  right: 0;
                  transform: translate(50%, -50%);
                }
              }

              &_arrow-l {
                position: relative;
                margin-left: 3rem;

                .arrow-r {
                  top: 50%;
                  left: 0;
                  transform: translate(-50%, -50%);
                }
              }

              &_prcs {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: rgba(19, 180, 151, 0.1);
                padding: 1rem 0;

                > :nth-child(2) {
                  border-left: 2px solid rgba(19, 180, 151, 0.1);
                  border-right: 2px solid rgba(19, 180, 151, 0.1);
                }

                .block {
                  width: calc(33% - .5rem);
                  padding: .25rem 1.5rem;

                  .ttl {
                    font-size: 10px;
                    text-align: left;
                    color: rgba(255, 255, 255, 0.5);
                  }

                  .val {
                    font-size: 1.5rem;

                    &-text {
                      font-size: 1rem;
                    }
                  }
                }
              }
            }
          }

          &__bankAcc {
            .ttl {
              svg.img {
                ::v-deep path {
                  fill: #24BAD6;
                }
              }
            }
          }

          &__merchantAcc {
            .ttl {
              svg.img {
                ::v-deep path {
                  fill: #FF056C;
                }
              }
            }
          }
        }

        .footer {
          display: flex;
          justify-content: flex-end;
          padding-bottom: 1rem;

          .btn {
            &_submit {
              padding: 1.25rem 3.5rem;
              margin: .25rem .75rem;
              background: linear-gradient(113.24deg, #13B497 16.01%, #05737A 106.71%);
              box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
              border-radius: 6px;
            }
          }
        }
      }
    }
  }

  .arrow-r {
    position: absolute;
    width: 3rem;
    height: 3rem;
    background: linear-gradient(113.24deg, #13B497 16.01%, #05737A 106.71%);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    ::v-deep svg {
      width: 65%;
      height: 65%;

      path {
        fill: #ffffff;
      }
    }
  }

  // .inpt {
    // &_procCurr {
    //   width: 50%;
    //   margin-left: 45%;
    // }
  // }
}

.form-control {
    width: 100%;
    height: 100%;
    color: #ffffff;
    background-color: #1C2126;
    font-size: 14px;
    padding: 0.75rem;
    margin: 1rem 0rem;
    border: 1px solid #2F363D;
    border-radius: 0.5rem;
    box-shadow: 0px 11px 23px rgb(0 0 0 / 2%);
    outline: none;
    -webkit-appearance: none;
}

.invalid-feedback {
    font-size: 10px;
    width: 100%;
    margin-top: .25rem;
    color: #dc3545;
}
</style>